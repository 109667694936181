import React from 'react';

import logo from '../img/logo.svg';

const Cover = () => (
  <div className="Cover" key="cover">
    {/* <p>MESTIZA</p> */}
    <img className="Cover-logo" src={logo} />
  </div>
);

export default Cover;
